import React, { useEffect, useState } from 'react'
import axios from 'axios'
import logo from '../../assets/logo.svg';
import Loader from '../../components/Loader/Loader';
import Url from "../../components/Element/Api";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const Transaction = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [transactiondata, setTransactionData] = useState([]);
    const [initial, setInitial] = useState(true);


    const getTransaction = async () => {
        try {
            setLoader(true);
            const response = await axios.get(`${Url.BASEURL}/v1/sync_transaction`);

            if (response) {
                setLoader(false);
                setTransactionData(response?.data?.Data || [])
                toast.success(response.data.message);

            }

        } catch (error) {
            toast.error("error", error);
        }
    }


    useEffect(() => {
        getTransaction();
    }, [])

    const goback = () => {
        navigate('/')
    }


    return (
        <>
            <div className='layout'>
                <div className='flex justify-center flex-col items-center'>
                    <div className='text-center mt-7'>
                        <img src={logo} alt='logo' className='mx-auto' width={200} height={200} />
                    </div>
                    <p className='text-zinc-500 text-xl mt-2'>Transactions Details</p>
                </div>
                {loader ? (
                    <Loader />
                ) : transactiondata.length === 0 ? (
                    <p className="text-center mt-6 light-bg-L p-10">No Transactions Found</p>
                ) : (
                    <div>

                        <div className='h-[500px] overflow-y-auto mt-11'>
                            <table className='w-[1300px] bg-white border'>
                                <thead className=''>
                                    <tr>
                                        <th className='bg-green-800 text-left text-white py-2 px-4 sticky top-0'>Source</th>
                                        <th className='bg-green-800 text-left text-white py-2 px-4 sticky top-0'>Customer</th>
                                        <th className='bg-green-800 text-left text-white py-2 px-4 sticky top-0'>Transaction ID</th>
                                        <th className='bg-green-800 text-left text-white py-2 px-4 sticky top-0'>Order ID</th>
                                        <th className='bg-green-800 text-left text-white py-2 px-4 sticky top-0'>Amount</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {transactiondata?.map(transaction => (
                                        <tr key={transaction.transactionId} className='border-t'>
                                            <td className='py-2 px-4  text-left text-gray-600'>{transaction?.source}</td>
                                            <td className='py-2 px-4 text-left text-gray-600'> {transaction?.customername ? transaction?.customername : 'N/A'}</td>
                                            <td className='py-2 px-4 text-left text-gray-600'>{transaction?.transactionId}</td>
                                            <td className='py-2 px-4 text-left text-gray-600'>{transaction?.orderId}</td>
                                            <td className='py-2 px-4 text-left text-gray-600'> $ {transaction ? (transaction.amount / 100).toFixed(2) : 'N/A'}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}


                <div>
                    <button
                        className={`bg-green-800 text-white font-bold py-2 px-6 rounded-lg mt-8`}
                        onClick={goback}
                    >
                        Go Back
                    </button>
                </div>
            </div>
        </>
    )
}

export default Transaction