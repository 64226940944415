import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Url from "../components/Element/Api";
import { useNavigate } from 'react-router-dom';

const CrmOauthpage = () => {
    const navigate = useNavigate();
    const [initialLoad, setInitialLoad] = useState(true);
    const storedMerchantId = localStorage.getItem('merchantId');




    const crmAccesstoken = async () => {
        if (initialLoad) {

            const queryParams = new URLSearchParams(window.location.search);
            const authorizationCode = queryParams.get("code");

            if (authorizationCode) {
                try {

                    const response = await axios.post(Url.BASEURL + '/v2/generate_accesstoken', {
                        code: authorizationCode,
                        merchantId: storedMerchantId
                    });
                    // localStorage.removeItem('merchantId');
                    navigate('/response');

                } catch (error) {
                    console.log("not getting any oauth code from the crm");
                }
            }
            setInitialLoad(false)
        }
    }

    useEffect(() => {
        crmAccesstoken();
    }, [initialLoad])


    return (
        <div className=' bg-slate-300 flex justify-center h-screen items-center '>
            {/* <p className=' text-2xl '>Your response was saved in CRM</p> */}
        </div>
    )
}

export default CrmOauthpage
