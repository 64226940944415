import React from "react";
import { CSSProperties } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import "./Loader.css"; // Create a Loader.css file for styling
// const override: CSSProperties = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };
const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-backdrop"></div>
      <BounceLoader
        color="#1d8830"
        loading
        // cssOverride={override}
        speedMultiplier={2}
      />
    </div>
  );
};

export default Loader;
