import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from '../screens/Home';
import MerchantData from '../screens/MerchantData'
import CrmOauthpage from '../screens/CrmOauthpage';
import MessagePage from '../screens/MessagePage';
import Transaction from '../screens/Transaction/Transaction';

const Approutes = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/merchant-data' element={<MerchantData />} />
                <Route path='/crmouath' element={<CrmOauthpage />} />
                <Route path='/transaction' element={<Transaction />} />
                <Route path='/response' element={<MessagePage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default Approutes
