import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Url from "../components/Element/Api";
import "./Home.css";
import logo from '../assets/logo.svg';
import finallogo from '../assets/customerfinal.png'
import customers from '../assets/Customers.svg'
import Transaction from '../assets/Transaction.svg'
import Clover from '../assets/Clover.svg'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader/Loader';

const Home = () => {
    const navigate = useNavigate();
    const { locationId } = useParams();
    const storedMerchantId = localStorage.getItem('merchantId');


    const [loader, setLoader] = useState(false);
    const [transactiondata, setTransactionData] = useState([]);
    const [initial, setInitial] = useState(true);
    const [user, setUser] = useState("");
    const isDisabled = !user;


    const getUser = async () => {
        try {
            const response = await axios.get(`${Url.BASEURL}/v2/getdata`, {
                params: { merchantId: storedMerchantId }
            })
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch the user data');
        }
    }
    useEffect(() => {
        getUser();
    }, [])



    const authurl = async () => {
        const apidata = await axios.get(`${Url.BASEURL}/v1/getAuthUrl`);
        if (apidata.status === 200) {
            const authUrl = apidata.data.authUrl;
            window.open(authUrl, '_blank');
            setLoader(false);
        } else {
            console.error('Failed to fetch the auth URL');
            setLoader(false);
        }
    }

    const handleSubmit = async () => {
        setLoader(true);
        setTimeout(async () => {
            if (user) {
                try {
                    const response = await axios.post(`${Url.BASEURL}/v2/userDisconnect`, {
                        merchantId: storedMerchantId
                    })
                    localStorage.removeItem('merchantId');

                    if (response.status === 200) {
                        setLoader(false);
                        toast.success(response.data.message);
                        setTimeout(() => {
                            window.location.reload();
                        }, 2000)
                    }

                } catch (error) {
                    toast.error('Internal server error', error);
                }
            }
            else {

                await authurl();
            }

        }, 3000);
    }

    const SyncCustomers = async () => {
        try {
            setLoader(true);
            const response = await axios.get(`${Url.BASEURL}/v2/sync_customers`, {
                params: { merchantId: storedMerchantId }
            });

            if (response) {
                setLoader(false);
                toast.success(response.data.message);
            }

            // if (response.data.elements && response.data.elements.length === 0) {
            //     setLoader(false);
            //     // alert("No Customers found!")
            //     toast.success('No Customers found!')
            // } else {
            //     setLoader(false);
            //     toast.success(response.data.message);

            // }
        } catch (error) {
            toast.error("error", error);
        }
    }


    const syncTransaction = async () => {
        try {
            setLoader(true);
            const response = await axios.get(`${Url.BASEURL}/v1/sync_transaction`, {
                params: { merchantId: storedMerchantId }
            });

            if (response) {
                setLoader(false);
                // setTransactionData(response?.data?.Data || [])
                toast.success(response.data.message);
            }

        } catch (error) {
            toast.error("error", error);
        }
    }


    return (
        <>
            {loader && (
                <Loader />
            )}
            <div className='layout'>

                <div className='text-center justify-center flex flex-col  items-center mt-7'>
                    <img src={finallogo} alt='logo' className='mx-auto' width={200} height={200} />
                    <p className='text-zinc-500 text-xl mt-2'>Select an Option to Proceed</p>
                </div>
                <div className='flex flex-row justify-around w-[100%] mt-28'>
                    <div className=' flex  justify-center  w-[380px] h-[370px] border rounded-2xl bg-gray-100' role='button' onClick={handleSubmit} >
                        <div className=' flex  flex-col justify-center'>
                            <img src={Clover} className=' mx-auto' alt='logo' height={100} width={120} />
                            {/* <img src={connecttext} className=' mx-auto' alt='logo' height={31} width={110} /> */}
                            <div className=' flex justify-center flex-col mt-6'>
                                <p className='mx-auto  text-zinc-700 text-xl'>Connect to</p>

                                {
                                    user ? (
                                        <>
                                            <p className='text-xl text-zinc-800 font-bold'>{user.data}</p>
                                            <div className='flex justify-center mt-4'>
                                                <button className='text-xl text-white font-bold bg-green-800 hover:bg-green-600 py-2 px-4 rounded' >Disconnect</button>
                                            </div>
                                        </>

                                    ) : (
                                        <p className='text-4xl text-zinc-800 font-bold'>Clover POS</p>
                                    )
                                }
                                {/* <p className=' text-4xl  text-zinc-800 font-bold'>Clover POS</p> */}
                            </div>
                            {/* <img src={clovertext} className=' mx-auto' alt='logo' height={42} width={180} /> */}
                        </div>
                    </div>
                    <div className="border-l-2 border-gray-200 h-full mx-2"></div>

                    <div className=' space-y-4 ' role='button' >
                        <div className={`flex flex-row items-center mx-6 justify-around w-[360px] h-[175px] border rounded-2xl bg-gray-100 ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                            role='button'
                            onClick={isDisabled ? null : SyncCustomers}>
                            <div>
                                <img src={customers} alt='logo' height={90} width={100} />
                            </div>
                            <div>
                                <p className=' text-2xl font-normal'>Sync</p>
                                <p className=' text-3xl  text-zinc-800 font-bold'>Customers</p>
                                {/* <img src={Sync} alt='logo' height={31} width={55} />
                                <img src={customerstext} alt='logo' height={30} width={170} /> */}
                            </div>
                        </div>
                        <div className={`flex flex-row items-center mx-6 justify-around w-[360px] h-[175px] border rounded-2xl bg-gray-100 ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                            role='button'
                            onClick={isDisabled ? null : syncTransaction}>
                            <div>
                                <img src={Transaction} alt='logo' height={90} width={100} />
                            </div>
                            <div>
                                <p className=' text-2xl font-normal'>Sync</p>
                                <p className=' text-3xl  text-zinc-800 font-bold'>Transaction</p>
                                {/* <img src={Sync} alt='logo' height={31} width={55} />
                                <img src={Paymenttext} alt='logo' height={30} width={170} /> */}
                            </div>
                        </div>

                    </div>
                </div>


                {/* <div>
                <button
                    className={`bg-green-500 text-white font-bold py-4 px-8 rounded-lg mt-8 ${!locationId ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-600'}`}
                    onClick={() => window.location.href = `https://app.customersync.ai/v2/location/${locationId}/contacts/smart_list/All`}
                    disabled={!locationId}
                >
                    Go Back to CRM
                </button>
            </div> */}
            </div>
        </>

    )
}

export default Home
