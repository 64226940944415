import React from "react";
import "./MessagePage.css";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../assets/logo.svg";
import finallogo from '../assets/customerfinal.png'
import clover from "../assets/Clover.svg";
import clovertext from "../assets/Clovertext.svg";

const MessagePage = () => {
    const navigate = useNavigate();
    const { locationId } = useParams();


    return (
        <div className="flex justify-center flex-col items-center min-h-screen">
            <div className="text-center ">
                <img
                    src={finallogo}
                    alt="logo"
                    className="mx-auto"
                    width={368}
                    height={62}
                />
            </div>
            <div className="text-center  mt-3">
                <p
                    className=" text-2xl"
                    style={{
                        fontSize: "36px",
                        fontWeight: 300,
                        lineHeight: "46.87px",
                        textAlign: "center",
                        width: "270px",
                        height: "47px",
                    }}
                >
                    Is Connected with
                </p>
            </div>

            <div className="text-center mt-7 gap-8 flex flex-row">
                <img
                    src={clover}
                    alt="logo"
                    className="mx-auto"
                    width={59}
                    height={59}
                />
                <img
                    src={clovertext}
                    alt="logo"
                    className="mx-auto"
                    width={192}
                    height={78}
                />
            </div>


            <div>
                <button class="bg-green-600 text-white font-bold py-2 px-8  rounded-lg hover:bg-green-800 mt-16" onClick={() => navigate(`/`)}>
                    Go Back
                </button>
            </div>
        </div>
    );
};

export default MessagePage;
