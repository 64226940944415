import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Url from "../components/Element/Api";
import { useNavigate } from 'react-router-dom';

const MerchantData = () => {
    const navigate = useNavigate();
    const [initialLoad, setInitialLoad] = useState(true)


    const cloverAccessToken = async () => {
        const queryParams = new URLSearchParams(window.location.search);
        const authorizationCode = queryParams.get("code");
        const merchantId = queryParams.get("merchant_id");
        const employee_id = queryParams.get("employee_id");

        // console.log("Authorization Code:", authorizationCode);
        // console.log("Merchant ID:", merchantId);
        // console.log("Employee ID:", employee_id);

        if (authorizationCode && initialLoad) {

            try {
                const response = await axios.post(Url.BASEURL + '/v1/getMerchantData', null, {
                    params: {
                        code: authorizationCode,
                        merchantId: merchantId,
                        employee_id: employee_id
                    },
                });
                setInitialLoad(false);


                if (response.data && response.data.success) {
                    try {
                        localStorage.setItem('merchantId', response.data.merchantid);
                        const crmapi = await axios.get(`${Url.BASEURL}/v2/crmAuth`);
                        if (crmapi.status === 200) {
                            const authUrl = crmapi.data.authUrl;
                            window.location.href = authUrl;
                        } else {
                            console.error('Failed to fetch the auth URL');
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }

            } catch (error) {
                console.error("Error fetching merchant data: >>>>>>>>>>>>", error.response.data);
            }
        }
    };
    useEffect(() => {
    }, [initialLoad])

    useEffect(() => {
        cloverAccessToken();
    }, [])

    return (
        <div className="flex justify-center items-center h-screen bg-slate-200">

        </div>
    )
}

export default MerchantData
